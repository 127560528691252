import { editAdmin } from "@/api/api";
import { stateList } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      cityValue: [],
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      Info: {
        id: '',
        name: '',
        user_type: 12
      },
      stateList: stateList,
      adminList: [],
      levelList: [],
      type: 0,
      checkMuneSer: [],
      rules: {
        username: [{
          required: true,
          message: "用户名不能为空",
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: "密码不能为空",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      this.cityValue = [parseInt(this.Info.province), parseInt(this.Info.city), parseInt(this.Info.area)]; //回显省市区县
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (!this.Info.password) {
            this.$root.ElMessage.error('密码不能为空');
          }
          editAdmin(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    }
  }
};